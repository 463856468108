
import { Options, Vue } from 'vue-class-component';
import Editor from 'wangeditor';
@Options({
    props: {
        data: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            editor: ''
        }
    },
    emits: ['change', 'update:data'],
    watch: {
        data(nVal){
            if(this.editor.txt.html() === '')
                this.editor.txt.html(nVal);
        }
    },
    mounted(){
        let that = this;
        this.html = this.data;
        this.editor = new Editor('#toolsbar', '#content');
        
        Object.assign(that.editor.config, {
            onchange(){
                that.$emit('change', that.editor.txt.html());
                that.$emit('update:data', that.editor.txt.html());
            },
            uploadImgShowBase64: true,
            focus: false,
            zIndex: 2
        });
        this.editor.create();
        this.editor.txt.html(this.data);
    },
    unmounted(){
        this.editor.destroy();
        this.editor = '';
    }
})

export default class NEditor extends Vue {}
